import { useCallback } from 'react';
// @mui
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
// locales
import { useTheme } from '@mui/material/styles';
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function LanguagePopoverText() {
    const locales = useLocales();

    const popover = usePopover();
    const theme = useTheme();
    const handleChangeLang = useCallback(
        (newLang) => {
            locales.onChangeLang(newLang);
            popover.onClose();
        },
        [locales, popover]
    );

    return (
        <>
            <Link
                sx={{mr:2, color: theme.palette.primary.main}}
                component="button"
                variant="body2"
                whileTap="tap"
                whileHover="hover"
                variants={ varHover(1.05) }
                onClick={ popover.onOpen }
                >
                    { locales.t('profile.language') }
            </Link>

            <CustomPopover open={ popover.open } onClose={ popover.onClose } sx={{ width: 160 }}>
                { locales.allLangs.map((option) => (
                    <MenuItem
                        key={ option.value }
                        selected={ option.value === locales.currentLang.value }
                        onClick={ () => handleChangeLang(option.value) }
                    >
                        <Iconify icon={ option.icon } sx={{ borderRadius: 0.65, width: 28 }} />

                        { option.label }
                    </MenuItem>
                ))}
            </CustomPopover>
        </>
    );
}
