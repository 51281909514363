import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { useLocales } from 'src/locales';


export default function SearchNotFound({ query, sx, ...other }) {

  const { t } = useLocales();

  return query.length > 3 ? (
    <Paper
      sx={{
        bgcolor: 'unset',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" gutterBottom>
        No se han encontrado resultados para <strong>&quot;{query}&quot;</strong>.
      </Typography>

      <Typography variant="body2">
        Revísa que está bien escrito o intenta escribir palabras completas.
      </Typography>
    </Paper>
  ) : (
    <Paper
      sx={{
        bgcolor: 'unset',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" gutterBottom>
        {t('common.searchMinLenght')}
      </Typography>
    </Paper>
  );
}

SearchNotFound.propTypes = {
  query: PropTypes.string,
  sx: PropTypes.object,
};
