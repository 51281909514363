import { GoogleOAuthProvider } from '@react-oauth/google';

// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// license
import { LicenseInfo } from '@mui/x-license-pro';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context';
// data
import { DataProvider } from 'src/context/data-context';
// config
import { GOOGLE_CLIENT_ID } from './config-global';

// ----------------------------------------------------------------------

LicenseInfo.setLicenseKey('3c989f8430b5c7ddcb97af246ed5a950Tz02NzQ2NyxFPTE3MTY5ODUzODkxNTUsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

// ----------------------------------------------------------------------

export default function App() {

    useScrollToTop();

    return (
        <GoogleOAuthProvider clientId={ GOOGLE_CLIENT_ID }>
            <AuthProvider>
                <LocalizationProvider>
                    <SettingsProvider
                        defaultSettings={{
                            themeMode: 'light', // 'light' | 'dark'
                            themeDirection: 'ltr', //  'rtl' | 'ltr'
                            themeContrast: 'default', // 'default' | 'bold'
                            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                            themeColorPresets: 'red', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                            themeStretch: false,
                        }}
                    >
                        <ThemeProvider>
                            <MotionLazy>
                                <SnackbarProvider>
                                    <SettingsDrawer />
                                    <ProgressBar />
                                    <AuthConsumer>
                                        <DataProvider>
                                            <Router />
                                        </DataProvider>
                                    </AuthConsumer>
                                </SnackbarProvider>
                            </MotionLazy>
                        </ThemeProvider>
                    </SettingsProvider>
                </LocalizationProvider>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}
