import _ from 'lodash';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import { bgBlur } from 'src/theme/css';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { useDataContext } from 'src/context/data-context';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import MailCompose from 'src/sections/content/blocks/mail-compose';

import { HEADER, NAV } from '../config-layout';
import {
    Searchbar,
    AccountPopover,
    SettingsButton,
    LanguagePopover
} from '../_common';


// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
    const theme = useTheme();

    const settings = useSettingsContext();

    const { userCommercialContacts, selectedEnvironment } = useDataContext();
    const userEnvironmentCommercialContact = _.find(userCommercialContacts, {id_environment: selectedEnvironment});

    const isNavHorizontal = settings.themeLayout === 'horizontal';

    const isNavMini = settings.themeLayout === 'mini';

    const lgUp = useResponsive('up', 'lg');

    const offset = useOffSetTop(HEADER.H_DESKTOP);

    const offsetTop = offset && !isNavHorizontal;

    const openCompose = useBoolean();

    const renderContent = (
        <>
            { lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} /> }

            { !lgUp && (
                <IconButton onClick={onOpenNav}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
                </IconButton>
            ) }

            <Searchbar />

            <Stack
                flexGrow={ 1 }
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1 }}
            >
                { userEnvironmentCommercialContact && <Button onClick={()=>openCompose.onToggle()} sx={{m:0, p:0, borderRadius:9}}><Chip                    
                    sx={{
                        borderRadius: 8,
                        p: 0.5,                        
                        backgroundColor: alpha(theme.palette.primary.main, 0.08),
                        border:"none !important",
                        height: 'auto',
                        textAlign: "left",
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                        }
                      }}
                    avatar={ <Avatar sx={{ width: "50px !important", height: "50px !important" }} alt={userEnvironmentCommercialContact.name} src={userEnvironmentCommercialContact.photo} /> }
                    label={<Stack sx={{m:0, p:1}} spacing={0}>
                            <Typography variant="caption">{userEnvironmentCommercialContact.name}</Typography>
                            <Stack direction="row" spacing={0.5}>
                                <Iconify color={theme.palette.primary.main} icon="material-symbols:mail-outline"/>
                                <Typography color={theme.palette.primary.main} sx={{textDecoration:"underline"}} variant="caption">Contactar</Typography>
                            </Stack>
                    </Stack>}                    
                    variant="outlined"
                /></Button> }
                <LanguagePopover />
                <SettingsButton />
                <AccountPopover />
            </Stack>
            {openCompose.value && <MailCompose environment={selectedEnvironment} onCloseCompose={openCompose.onFalse} />}
        </>
    );

    return (
        <AppBar
            sx={{
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
                    height: HEADER.H_DESKTOP,
                    ...(offsetTop && {
                        height: HEADER.H_DESKTOP_OFFSET,
                    }),
                    ...(isNavHorizontal && {
                        width: 1,
                        bgcolor: 'background.default',
                        height: HEADER.H_DESKTOP_OFFSET,
                        borderBottom: `dashed 1px ${theme.palette.divider}`,
                    }),
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_MINI + 1}px)`,
                    }),
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                }}
            >
                { renderContent }
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};
