export const paths = {
    auth: {
        jwt: {
            login: '/auth/login',
            register: '/auth/register',
            registerWithToken: '/auth/register/:method/:token',
            registerPendingEmail: '/auth/register/pending-email',
            registerPendingDelegation: '/auth/register/pending-delegation',
            forgotPassword: '/auth/forgot-password',
            newPassword: '/auth/new-password?email=:email'
        },
    },
    content: {
        home: '/home',
        titles: '/titles',
        gate: '/gate',
        levelTitles: '/titles/:levelId',
        serieTitles: '/titles/:levelId/:serieId',
        title: '/titles/:levelId/:serieId/:titleId',
        readersTitles: '/titles/readers',
        levelReadersTitles: '/titles/readers/:levelId',
        serieReadersTitles: '/titles/readers/:levelId/:serieId',
        readersTitle: '/titles/readers/:levelId/:serieId/:titleId',
        submenu: '/:slug',
        resources: '/:slug/resources/:section',
        resource: '/:slug/resources/:section/:resourceId/:childSlug',
        courses: '/:slug/courses/:section',
        course: '/:slug/courses/:section/:courseId/:childSlug',
        thinkoai: '/thinkoai',
        mathewai: '/mathewai',
        mathewaiPage: '/mathewai/intro',
    },
    page404: '/404'
};
