import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { HOST_API } from 'src/config-global';
import { defaultLang } from './config-lang';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng,
        fallbackLng: lng,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${ HOST_API }/api/locales/{{lng}}.json`,
        }
    });

export default i18n;
