import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useReducer, useContext, useMemo, useCallback, useEffect } from 'react';
import i18n from 'i18next';

import axios, { endpoints } from 'src/utils/axios';

const DataContext = createContext()

function dataReducer(state, action) {
    switch (action.type) {
        case 'setData': {
            return {
                ...state,
                ...action.payload
            };
        }
        case 'setResources': {
            const newState = { ...state };
            _.set(newState, `resources['${action.section}']`, action.resources)
            return newState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const loadData = async (url, defaultValue) => {
    try {
        return (await axios.get(url))?.data || defaultValue;
    } catch (err) {
        return defaultValue;
    }
}

const initialize = async (dispatch) => {

    loadData(endpoints.settings, []).then(settings => {
        dispatch({ type: 'setData', payload: { settings }});
    });

    loadData(endpoints.auth.series, []).then(userSeries => {
        dispatch({ type: 'setData', payload: { userSeries }});
    });

    loadData(endpoints.auth.environments, []).then(userEnvironments => {
        const selectedEnvironmentInLocalStorage = window.localStorage.getItem('selectedEnvironment');
        const selectedEnvironment = selectedEnvironmentInLocalStorage ? _.toInteger(selectedEnvironmentInLocalStorage) : _.get(userEnvironments, '[0].id_environment');
        dispatch({ type: 'setData', payload: { userEnvironments, selectedEnvironment }});

        changeEnvironment(dispatch, selectedEnvironment);

    });

    loadData(endpoints.auth.user, []).then(user => {
        dispatch({ type: 'setData', payload: { user }});

        loadData(endpoints.languages, []).then(languages => {
            dispatch({ type: 'setData', payload: { languages }});

            const userLanguage = _.find(languages, language => language.id_language === user.id_language);
            if (userLanguage && i18n.language !== userLanguage.iso639_2) {
                i18n.changeLanguage(userLanguage.iso639_2);
            }
        });
    });

    loadData(endpoints.auth.series, []).then(userSeries => {
        dispatch({ type: 'setData', payload: { userSeries }});
    });

    loadData(endpoints.auth.coursebooks, []).then(userCoursebooks => {
        dispatch({ type: 'setData', payload: { userCoursebooks }});
    });

    loadData(endpoints.environments, []).then(environments => {
        dispatch({ type: 'setData', payload: { environments }});
    });

    loadData(endpoints.types, []).then(types => {
        dispatch({ type: 'setData', payload: { types }});
    });

    loadData(endpoints.categories, []).then(categories => {
        dispatch({ type: 'setData', payload: { categories }});
    });

    loadData(endpoints.countries, []).then(countries => {
        dispatch({ type: 'setData', payload: { countries }});
    });

    loadData(endpoints.regions, []).then(regions => {
        dispatch({ type: 'setData', payload: { regions }});
    });

    loadData(endpoints.subregions, []).then(subregions => {
        dispatch({ type: 'setData', payload: { subregions }});
    });

    loadData(endpoints.schools, []).then(schools => {
        dispatch({ type: 'setData', payload: { schools }});
    });

}

const changeEnvironment = async (dispatch, id_environment) => {

    dispatch({ type: 'setData', payload: {
        selectedEnvironment: id_environment,
        commercialContacts: [],
        menu: [],
        highlights: [],
        levels: [],
        series: [],
        coursebooks: [],
        readersLevels: [],
        readersSeries: [],
        readers: []
    }});

    if (id_environment) {

        window.localStorage.setItem('selectedEnvironment', id_environment);

        loadData(`${endpoints.menu}?id_environment=${id_environment}`, []).then(menu => {

            dispatch({ type: 'setData', payload: { menu }});

            _.each(extractResourceSectionsFromMenuOptions(menu), section => {

                const payload = {};
                payload[`resources${section}`] = [];

                dispatch({ type: 'setData', payload });

                loadData(`${endpoints.resources.replace(':section', section)}?id_environment=${id_environment}`, []).then(resources => {
                    payload[`resources${section}`] = resources;
                    dispatch({ type: 'setData', payload });
                });

            });

        });

        loadData(`${endpoints.highlights}?id_environment=${id_environment}`, []).then(highlights => {
            dispatch({ type: 'setData', payload: { highlights }});
        });

        loadData(endpoints.auth.commercialContacts, []).then(userCommercialContacts => {
            dispatch({ type: 'setData', payload: { userCommercialContacts }});
        });

        loadData(`${endpoints.levels}?id_environment=${id_environment}`, []).then(levels => {
            dispatch({ type: 'setData', payload: { levels }});
        });

        loadData(`${endpoints.series}?id_environment=${id_environment}`, []).then(series => {
            dispatch({ type: 'setData', payload: { series }});
        });

        loadData(`${endpoints.coursebooks}?id_environment=${id_environment}`, []).then(coursebooks => {
            dispatch({ type: 'setData', payload: { coursebooks }});
        });

        loadData(`${endpoints.readersLevels}?id_environment=${id_environment}`, []).then(readersLevels => {
            dispatch({ type: 'setData', payload: { readersLevels }});
        });

        loadData(`${endpoints.readersSeries}?id_environment=${id_environment}`, []).then(readersSeries => {
            dispatch({ type: 'setData', payload: { readersSeries }});
        });

        loadData(`${endpoints.readers}?id_environment=${id_environment}`, []).then(readers => {
            dispatch({ type: 'setData', payload: { readers }});
        });

    } else {

        loadData(`${endpoints.levels}`, []).then(levels => {
            dispatch({ type: 'setData', payload: { levels }});
        });

        loadData(`${endpoints.series}`, []).then(series => {
            dispatch({ type: 'setData', payload: { series }});
        });

        loadData(`${endpoints.coursebooks}`, []).then(coursebooks => {
            dispatch({ type: 'setData', payload: { coursebooks }});
        });

    }

};

const extractResourceSectionsFromMenuOptions = (menu, sections = []) => {
    _.each(menu, menuOption => {
        if (menuOption.type === 'resources') {
            sections.push(menuOption.section);
        }
        if (menuOption.type === 'submenu') {
            sections = extractResourceSectionsFromMenuOptions(menuOption.children, sections);
        }
    });
    return _.uniq(_.compact(sections));
}

function DataProvider({children}) {

    const [state, dispatch] = useReducer(dataReducer, {});

    const initializeCallback = useCallback(async () => {
        initialize(dispatch);
    }, []);

    const changeEnvironmentCallback = useCallback((id_environment) => {
        changeEnvironment(dispatch, id_environment);
    }, []);

    useEffect(() => {
        initializeCallback();
    }, [initializeCallback]);

    const memoizedValue = useMemo(
        () => ({
            ...state,
            //
            initialize: initializeCallback,
            changeEnvironment: changeEnvironmentCallback
        }),
        [state, initializeCallback, changeEnvironmentCallback]
    );

    return <DataContext.Provider value={memoizedValue}>{children}</DataContext.Provider>

}

function useDataContext() {

    const context = useContext(DataContext)

    if (context === undefined) {

        throw new Error('useData must be used within a DataProvider')

    }

    return context
}

DataProvider.propTypes = {
    children: PropTypes.node,
};

export {DataProvider, useDataContext}