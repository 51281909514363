import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Stack from '@mui/material/Stack';
//
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

//
import { useRouter } from 'src/routes/hooks';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useDataContext } from 'src/context/data-context';

import { navMiniConfig } from '../config';
import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionMini({ data, config, sx, ...other }) {

  const { changeEnvironment, selectedEnvironment, userEnvironments, environments } = useDataContext();
  const popover = usePopover();
  const router = useRouter();

  return (
    <Stack sx={sx} {...other}>
      <Divider sx={{ mt: 2, mb: 2 }} variant="middle" />


      { _.compact(_.map(environments, environment => {
                if (_.find(userEnvironments, { id_environment: environment.id_environment })) {

                    return (environment.id_environment === selectedEnvironment) &&
                        (
                            <Button
                                key={ environment.id_environment }
                                size="small"
                                color="primary"
                                sx={{ width: 10, textOverflow: "ellipsis", overflow: "hidden" }}
                                onClick={popover.onOpen}

                            >
                                { environment.name }
                            </Button>
                        )
                }
                return null;
            }))}
            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="top-center"
                sx={{ width: 260 }}
            >
            { _.compact(_.map(environments, environment => {
                if (_.find(userEnvironments, { id_environment: environment.id_environment })) {
                    return (environment.id_environment !== selectedEnvironment) &&
                        (
                            <MenuItem
                            sx={{ width: 260 }}
                            key={environment.id_environment}
                            selected={selectedEnvironment === environment.id_environment}
                            onClick={() => {
                                popover.onClose();
                                router.replace('/');
                                changeEnvironment(environment.id_environment);

                            }}
                        >
                            {environment.name}
                        </MenuItem>
                        )
                }
                return null;
            }))}
            </CustomPopover>

            <Divider sx={{ mt: 2, mb: 2 }} variant="middle" />

      {data.map((group, index) => (
        <Group key={group.subheader || index} items={group.items} config={navMiniConfig(config)} />
      ))}


    </Stack>
  );
}

NavSectionMini.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
};

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

function Group({ items, config }) {
  return (
    <>
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
};
