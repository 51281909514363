import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { HeaderSimple as Header, LanguagePopoverText } from 'src/layouts/_common';
import LogoM from 'src/assets/images/macmillan-logo.png';
import Image from 'src/components/image';

export default function AuthModernCompactLayout({ children }) {

	const theme = useTheme();

	/*
	const renderHeaderLinks = (
	  <Stack spacing={ 4 } direction="row"
	  justifyContent="center"
	  alignItems="center" sx={{ mt: 5, p:2, zIndex:9999, borderRadius:1, position:'absolute', top:-10, right:26, backgroundColor: "#FFFFFF88"  }}>
		  
            <Link href="https://www.macmillaneducation.es" target="_blank" rel="noopener">
                <Image src={LogoM} sx={{ml:2,width:"50%"}}/>
            </Link>
		  <Divider sx={{backgroundColor: theme.palette.grey[500]}} orientation="vertical" variant="middle" flexItem />
		  <Link sx={{color: theme.palette.primary.main}} component="button" variant="body2" href="https://macmillaneducation.my.salesforce-sites.com/help/bg_productdetail?id=01t240000047akJAAQ&language=es&retURL=/help/bg_esHomePage" rel="noreferrer" target="_blank">FAQ</Link>
		  <Divider sx={{backgroundColor: theme.palette.grey[500]}} orientation="vertical" variant="middle" flexItem />
		  <LanguagePopoverText />
	  </Stack>
	);
	*/

	return (
		<>
			<Header />
			{ /* renderHeaderLinks */}

			<Box
				component="main"
				sx={{
					py: 12,
					display: 'flex',
					minHeight: '100vh',
					px: { xs: 2, md: 0 },
					position: 'relative',
					alignItems: 'center',
					justifyContent: 'center',
					'&:before': {
						width: 1,
						height: 1,
						zIndex: -1,
						content: "''",
						opacity: 0.24,
						position: 'absolute',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center center',
						backgroundImage: 'url(/assets/background/overlay_1.jpg)',
					},
				}}
			>
				<Card
					sx={{
						py: 5,
						px: 3,
						width: { xs: '90%', md: '60%' },
					}}
				>
					{children}
				</Card>


			</Box>
		</>
	);
}

AuthModernCompactLayout.propTypes = {
	children: PropTypes.node,
};
