import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Portal from '@mui/material/Portal';
import Backdrop from '@mui/material/Backdrop';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import axios, { endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

const ZINDEX = 1998;

const POSITION = 24;

export default function MailCompose({ environment, onCloseCompose }) {
    const { t } = useLocales();
    const smUp = useResponsive('up', 'sm');

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fullScreen = useBoolean();

    const handleChangeSubject = useCallback((value) => {
        setSubject(value?.target?.value || '');
    }, []);

    const handleChangeMessage = useCallback((value) => {
        setMessage(value?.target?.value || '');
    }, []);

    const sendMessage = async () => {

        const data = { environment, subject, message };
        setIsSubmitting(true);

        try {

            const response = (await axios.post(`${endpoints.auth.message}`, data)).data || {};
            setIsSubmitting(false);
            onCloseCompose();
            
        } catch (error) {

            setIsSubmitting(false);
            alert(t('validations.somethingWentWrong'));

        }
    }

    return (
        <Portal>
            {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}

            <Paper
                sx={{
                    right: 0,
                    bottom: 0,
                    borderRadius: 2,
                    display: 'flex',
                    position: 'fixed',
                    zIndex: ZINDEX + 1,
                    m: `${POSITION}px`,
                    overflow: 'hidden',
                    flexDirection: 'column',
                    minWidth: `calc(min(100% - ${POSITION * 2}px, 600px))`,
                    boxShadow: (theme) => theme.customShadows.dropdown,
                    ...(fullScreen.value && {
                        m: 0,
                        right: POSITION / 2,
                        bottom: POSITION / 2,
                        width: `calc(100% - ${POSITION}px)`,
                        height: `calc(100% - ${POSITION}px)`,
                    }),
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        bgcolor: 'background.neutral',
                        p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
                    }}
                >
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        { t('mail.title') }
                    </Typography>

                    <IconButton onClick={fullScreen.onToggle}>
                        <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} />
                    </IconButton>

                    <IconButton onClick={onCloseCompose}>
                        <Iconify icon="mingcute:close-line" />
                    </IconButton>
                </Stack>

                <InputBase
                    value={subject}
                    onChange={handleChangeSubject}
                    placeholder={ t('mail.subject') }
                    sx={{
                        px: 2,
                        height: 48,
                        borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                    }}
                />

                <Stack spacing={2} flexGrow={1} sx={{ p: 2 }}>
                    <TextField 
                        fullWidth 
                        multiline 
                        rows={6}
                        onChange={handleChangeMessage}
                        value={message}
                        placeholder={ t('mail.message') }
                    />

                    <Stack direction="row" alignItems="center">
                        <Stack direction="row" alignItems="center" flexGrow={1} />
                        <LoadingButton
                            loading={isSubmitting}
                            variant="contained"
                            color="primary"
                            endIcon={<Iconify icon="iconamoon:send-fill" />}
                            onClick={() => sendMessage()}
                        >
                            { t('common.send') }
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Paper>
        </Portal>
    );
}

MailCompose.propTypes = {
    environment: PropTypes.number,
    onCloseCompose: PropTypes.func,
};
