import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';

import Iconify from 'src/components/iconify';
import { useRouter } from 'src/routes/hooks';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Image from 'src/components/image';
import { useDataContext } from 'src/context/data-context';
import { useLocales } from 'src/locales';
import { navVerticalConfig } from '../config';
import { StyledSubheader } from './styles';
import NavList from './nav-list';
import Empty from "../../../assets/images/help_empty.png";
import LogoM from "../../../assets/images/macmillan-logo.png";

function NavSectionVertical({ data, config, sx, ...other }) {

    const theme = useTheme();
    const { changeEnvironment, selectedEnvironment, userEnvironments, environments } = useDataContext();
    const popover = usePopover();
    const router = useRouter();
    const { t } = useLocales();

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
            <Stack sx={{ ...sx, width: '100%' }} { ...other }>
                <Divider sx={{ mt: 2, mb: 2 }} variant="middle" />
                { _.compact(_.map(environments, environment => {
                    if (_.find(userEnvironments, { id_environment: environment.id_environment })) {

                        return (environment.id_environment === selectedEnvironment) &&
                            (
                                <Button
                                    key={ environment.id_environment }
                                    size="small"
                                    color="primary"
                                    onClick={popover.onOpen}
                                    endIcon={<Iconify icon="eva:arrow-ios-downward-fill" sx={{ ml: -0.5 }} />}
                                    sx={{ ml:2, mr:2 }}
                                >
                                    { environment.name }
                                </Button>
                            )
                    }
                    return null;
                }))}
                <CustomPopover
                    open={popover.open}
                    onClose={popover.onClose}
                    arrow="top-center"
                    sx={{ width: 260 }}
                >
                { _.compact(_.map(environments, environment => {
                    if (_.find(userEnvironments, { id_environment: environment.id_environment })) {
                        return (environment.id_environment !== selectedEnvironment) &&
                            (
                                <MenuItem
                                sx={{ width: 260 }}
                                key={environment.id_environment}
                                selected={selectedEnvironment === environment.id_environment}
                                onClick={() => {
                                    popover.onClose();
                                    router.replace('/');
                                    changeEnvironment(environment.id_environment);
                                }}
                            >
                                {environment.name}
                            </MenuItem>
                            )
                    }
                    return null;
                }))}
                </CustomPopover>
                <Divider sx={{ mt: 2, mb: 2 }} variant="middle" />
                { data.map((group, index) => (
                    <Group
                        key={ group.subheader || index }
                        subheader={ group.subheader }
                        items={ group.items }
                        config={ navVerticalConfig(config) }
                    />
                ))}
            </Stack>
            <Divider sx={{ mt: 2, mb: 2 }} variant="middle" />
            <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={6}>
                <Link href="https://www.macmillaneducation.es/atencion-al-cliente/" target="_blank" rel="noopener" sx={{ width: "100%", p: 0, height: 250, textAlign: "center", color: theme.palette.primary.main, backgroundSize: "100%", backgroundRepeat: "no-repeat", pt: 18, backgroundImage: `url(${ Empty })` }}>
                    <Stack direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={ 0 }>
                        <Typography sx={{ fontWeight: "bold" }}>{ t('menu.helpdesk') }</Typography>
                        <Typography variant='caption'>{ t('menu.helpdeskQuestion') }</Typography>
                    </Stack>
                </Link>
                <Link href="https://www.macmillaneducation.es" target="_blank" rel="noopener">
                    <Image src={LogoM}/>
                </Link>
            </Stack>
        </Stack>
    );
}

NavSectionVertical.propTypes = {
    config: PropTypes.object,
    data: PropTypes.array,
    sx: PropTypes.object,
};

export default memo(NavSectionVertical);

// ----------------------------------------------------------------------

function Group({ subheader, items, config }) {
    const [open, setOpen] = useState(true);

    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, []);

    const renderContent = items.map((list) => (
        <NavList
            key={ list.title + list.path }
            data={ list }
            depth={ 1 }
            hasChild={ !!list.children }
            config={ config }
        />
    ));

    return (
        <List disablePadding sx={{ px: 2 }}>
            { subheader ? (
                <>
                    <StyledSubheader disableGutters disableSticky onClick={ handleToggle } config={ config }>
                        { subheader }
                    </StyledSubheader>

                    <Collapse in={ open }>{ renderContent }</Collapse>
                </>
            ) : (
                renderContent
            )}
        </List>
    );
}

Group.propTypes = {
    config: PropTypes.object,
    items: PropTypes.array,
    subheader: PropTypes.string,
};
