// @mui
import { alpha } from '@mui/material/styles';
// theme
import { palette as themePalette } from 'src/theme/palette';

// ----------------------------------------------------------------------

export function presets(presetsColor) {
  const primary = primaryPresets.find((i) => i.name === presetsColor);

  const theme = {
    palette: {
      primary,
    },
    customShadows: {
      primary: `0 8px 16px 0 ${alpha(`${primary?.main}`, 0.24)}`,
    },
  };

  return theme;
}

// ----------------------------------------------------------------------

const palette = themePalette('light');

export const primaryPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.primary,
  },
  // GREEN
  {
    name: 'green',
    lighter: '#E3FBE2',
    light: '#A3E7AD',
    main: '#5AB178',
    dark: '#2D7F59',
    darker: '#115443',
    contrastText: '#FFFFFF',
  },
  // YELLOW
  {
    name: 'yellow',
    lighter: '#FEF5DB',
    light: '#F9D892',
    main: '#EEAC4A',
    dark: '#AB6A25',
    darker: '#72390E',
    contrastText: '#FFFFFF',
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#DBE5FA',
    light: '#8FA7E2',
    main: '#3E559F',
    dark: '#1F2E72',
    darker: '#0B144C',
    contrastText: '#FFFFFF',
  },
  // RED
  {
    name: 'red',
    lighter: '#FCDECF',
    light: '#EF8470',
    main: '#CC1719',
    dark: '#920B24',
    darker: '#610426',
    contrastText: '#FFFFFF',
  },
];
