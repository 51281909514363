import _ from 'lodash';
import { useCallback } from 'react';
import { m } from 'framer-motion';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// context
import { useDataContext } from 'src/context/data-context';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// axios
import axios, { endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const locales = useLocales();
    const popover = usePopover();
    const { languages } = useDataContext();

    const handleChangeLang = useCallback(newLang => {

        locales.onChangeLang(newLang);

        const newLanguage = _.find(languages, language => language.iso639_2 === newLang);
        if (newLanguage) {
            axios.put(endpoints.auth.user, { language: newLanguage.id_language });
        }

        popover.onClose();

    }, [languages, locales, popover]);

    return <>
        <IconButton
            component={ m.button }
            whileTap="tap"
            whileHover="hover"
            variants={ varHover(1.05) }
            onClick={ popover.onOpen }
            sx={{
                width: 40,
                height: 40,
                ...(popover.open && {
                    bgcolor: 'action.selected',
                }),
            }}
        >
            <Iconify icon={ locales.currentLang.icon } sx={{ borderRadius: 0.65, width: 28 }} />
        </IconButton>

        <CustomPopover open={ popover.open } onClose={ popover.onClose } sx={{ width: 160 }}>
            { locales.allLangs.map((option) => (
                <MenuItem
                    key={ option.value }
                    selected={ option.value === locales.currentLang.value }
                    onClick={ () => handleChangeLang(option.value) }
                >
                    <Iconify icon={ option.icon } sx={{ borderRadius: 0.65, width: 28 }} />

                    { option.label }
                </MenuItem>
            ))}
        </CustomPopover>
    </>;
}
