import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import { useDataContext } from 'src/context/data-context';
import { paramCase } from 'src/utils/change-case';
import {ReactComponent as TitlesSVG } from '../../assets/images/titles.svg';
import {ReactComponent as TeacherSVG } from '../../assets/images/teacher.svg';
import {ReactComponent as CoursesSVG } from '../../assets/images/courses.svg';
import {ReactComponent as HomeSVG } from '../../assets/images/home.svg';
import {ReactComponent as BackSVG } from '../../assets/images/backtoschool.svg';
import {ReactComponent as ResourcesSVG } from '../../assets/images/resources.svg';
import {ReactComponent as GateSVG } from '../../assets/images/gate.svg';
import {ReactComponent as BrainSVG } from '../../assets/images/brain.svg';

export function useNavData() {
    const { t } = useLocales();

    const { selectedEnvironment, menu, levels, series, userSeries, readersLevels, readersSeries } = useDataContext();

    const [myTitlesChildrens, setMyTitlesChildrens] = useState([]);

    useEffect(() => {
        const childrens = [];
        _.each(levels, level => {
            if (level.id_environment === selectedEnvironment) {
                const levelSeries = _.filter(series, serie => serie.id_level === level.id_level && _.find(userSeries, userSerie => userSerie.id_serie === serie.id_serie));
                if (_.size(levelSeries)) {
                    const levelChildrens = _.map(_.sortBy(levelSeries, ['name']), levelSerie => ({ 
                        title: levelSerie.name, 
                        path: paths.content.serieTitles
                                                .replace(':levelId', level.id_level)
                                                .replace(':serieId', levelSerie.id_serie)
                                                .replace(':serieName', paramCase(levelSerie.name)) 
                    }));
                    childrens.push({
                        path: paths.content.levelTitles.replace(':levelId', level.id_level),
                        title: level.name,
                        children: levelChildrens
                    });
                }
            }
        });

        const readersChildrens = [];
        _.each(_.sortBy(readersLevels, ['id_order']), readersLevel => {
            if (readersLevel.id_environment === selectedEnvironment) {

                const readersLevelChildrens = [];
                _.each(_.sortBy(readersSeries, ['name']), readersSerie => {
                    if (readersSerie.readers_levels.includes(readersLevel.id_reader_level)) {
                        readersLevelChildrens.push({
                            title: readersSerie.name,
                            path: paths.content.serieReadersTitles
                                                    .replace(':levelId', readersLevel.id_reader_level)
                                                    .replace(':serieId', readersSerie.id_reader_serie)
                                                    .replace(':serieName', paramCase(readersSerie.name))
                        });
                    }
                });

                if (_.size(readersLevelChildrens)) {

                    readersChildrens.push({
                        path: paths.content.levelReadersTitles.replace(':levelId', readersLevel.id_reader_level),
                        title: readersLevel.name,
                        children: readersLevelChildrens
                    });

                }
            }
        });
        
        if (_.size(readersChildrens)) {
            childrens.push({
                path: paths.content.readersTitles,
                title: t('titles.readers'),
                children: readersChildrens
            });
        }

        if (_.size(childrens)) {
            childrens.push({
                path: paths.content.titles,
                title: t('titles.allTitles')
            });
        }

        setMyTitlesChildrens(childrens);
    }, [selectedEnvironment, levels, series, userSeries, readersLevels, readersSeries, t]);

    const loadIcon = (icon) => {

        if (!icon) {
            return null;
        }

        switch (icon) {
            case 'BackSVG':
                return <BackSVG/>
            case 'TeacherSVG':
                return <TeacherSVG/>
            case 'ResourcesSVG':
            default:
                return <ResourcesSVG/>
        }

    }

    const data = useMemo(
        () => {

            const parseMenuOption = (menuOption) => {

                switch (menuOption.type) {
                    case 'home':
                        return {
                            items: [
                                {
                                    title: t(`menu.${menuOption.ref}`),
                                    path: paths.content.home,
                                    icon: <HomeSVG/>
                                }
                            ]
                        };
        
                    case 'titles':
                        return {
                            items: [
                                {
                                    title: t(`menu.${menuOption.ref}`),
                                    path: paths.content.titles,
                                    icon: <TitlesSVG/>,
                                    children: myTitlesChildrens
                                }
                            ]
                        };

                    case 'gate':
                        return {
                            items: [
                                {
                                    title: t(`menu.${menuOption.ref}`),
                                    path: paths.content.gate,
                                    icon: <GateSVG/>
                                }
                            ]
                        };
                    
                    case 'resources':
                        return {
                            items: [
                                {
                                    title: t(`menu.${menuOption.ref}`),
                                    path: paths.content.resources.replace(':slug', menuOption.slug).replace(':section', menuOption.section),
                                    icon: loadIcon(menuOption.icon)
                                }
                            ]
                        };
        
                    case 'courses':
                        return {
                            items: [
                                {
                                    title: t(`menu.${menuOption.ref}`),
                                    path: paths.content.courses.replace(':slug', menuOption.slug).replace(':section', menuOption.section),
                                    icon: loadIcon(menuOption.icon),
                                }
                            ]
                        };

                    case 'thinkoai':
                        return {
                            items: [
                                {
                                    title: t(`menu.${menuOption.ref}`),
                                    path: `${window.location.protocol}//${window.location.hostname}:${window.location.port}${paths.content.thinkoai}`,
                                    icon: <BrainSVG/>,
                                }
                            ]
                        };

                    case 'mathewai':
                        return {
                            items: [
                                {
                                    title: t(`menu.${menuOption.ref}`),
                                    path: `${paths.content.mathewaiPage}`,
                                    icon: <BrainSVG/>,
                                }
                            ]
                        };
            
                    case 'submenu':
                        return {
                            items: [
                                {
                                    title: t(`menu.${menuOption.ref}`),
                                    path: paths.content.submenu.replace(':slug', menuOption.slug),
                                    icon: loadIcon(menuOption.icon),
                                    children: _.map(menuOption.children, item => _.get(parseMenuOption(item), 'items[0]'))
                                }
                            ]
                        };
                    
                    default: 
                        return null;
        
                }
        
            }

            const menuOptions = _.compact(_.map(menu, (item) => parseMenuOption(item)));

            return menuOptions;

        },
        [t, menu, myTitlesChildrens]
    );

    return data;
}
