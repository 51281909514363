import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthModernLayout from 'src/layouts/auth/modern';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import AuthModernCompactRegisterLayout from 'src/layouts/auth/modern-compact-register';
import { SplashScreen } from 'src/components/loading-screen';

const AuthLoginPage = lazy(() => import('src/pages/auth/login'));
const AuthRegisterPage = lazy(() => import('src/pages/auth/register'));
const AuthValidateEmailPage = lazy(() => import('src/pages/auth/validate-email'));
const AuthRegisterPendingDelegationPage = lazy(() => import('src/pages/auth/register-pending-delegation'));
const AuthRegisterPendingEmailPage = lazy(() => import('src/pages/auth/register-pending-email'));
const AuthForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const AuthNewPasswordPage = lazy(() => import('src/pages/auth/new-password'));

export const authRoutes = [
    {
        path: 'auth',
        element: (
            <GuestGuard>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </GuestGuard>
        ),
        children: [
            {
                path: 'login',
                element: (
                    <AuthModernLayout>
                        <AuthLoginPage />
                    </AuthModernLayout>
                ),
            },
            {
                path: 'register',
                element: (
                    <AuthModernCompactRegisterLayout>
                        <AuthRegisterPage />
                    </AuthModernCompactRegisterLayout>
                ),
            },
            {
                path: 'register/:method/:token',
                element: (
                    <AuthModernCompactRegisterLayout>
                        <AuthRegisterPage />
                    </AuthModernCompactRegisterLayout>
                ),
            },
            {
                path: 'validate-email/:pendingUserId/:hash',
                element: (
                    <AuthModernCompactLayout>
                        <AuthValidateEmailPage />
                    </AuthModernCompactLayout>
                ),
            },
            {
                path: 'register/pending-delegation',
                element: (
                    <AuthModernCompactLayout>
                        <AuthRegisterPendingDelegationPage />
                    </AuthModernCompactLayout>
                ),
            },
            {
                path: 'register/pending-email',
                element: (
                    <AuthModernCompactLayout>
                        <AuthRegisterPendingEmailPage />
                    </AuthModernCompactLayout>
                ),
            },
            {
                path: 'forgot-password',
                element: (
                    <AuthModernCompactLayout>
                        <AuthForgotPasswordPage />
                    </AuthModernCompactLayout>
                ),
            },
            {
                path: 'new-password',
                element: (
                    <AuthModernCompactLayout>
                        <AuthNewPasswordPage />
                    </AuthModernCompactLayout>
                ),
            },
        ],
    },
];
