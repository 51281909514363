import axios from 'axios';

import { HOST_API } from 'src/config-global';
import I18n from '../locales/i18n';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => Promise.reject((error.response && error.response.data) || I18n.t('validations.somethingWentWrong'))
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
    const [url, config] = Array.isArray(args) ? args : [args];
    const res = await axiosInstance.get(url, { ...config });
    return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
    auth: {
        login: '/api/auth',
        register: '/api/auth/register',
        validateEmail: '/api/auth/validate_email',
        forgotPassword: '/api/auth/forgot_password',
        newPassword: '/api/auth/new_password',
        user: '/api/auth/me',
        environments: '/api/auth/me/environments',
        series: '/api/auth/me/series',
        coursebooks: '/api/auth/me/coursebooks',
        commercialContacts: '/api/auth/me/commercial_contacts',
        message: '/api/auth/me/message',
        gate: '/api/auth/me/gate',
        checkAdvantageCode: '/api/auth/advantageCode/check'
    },
    environments: '/api/environments',
    languages: '/api/languages',
    levels: '/api/levels',
    series: '/api/series',
    serie: '/api/serie/:id_serie',
    types: '/api/types',
    categories: '/api/categories',
    coursebooks: '/api/coursebooks',
    coursebook: '/api/coursebook/:id_coursebook',
    coursebookPrograms: '/api/coursebook/:id_coursebook/programs',
    coursebookResources: '/api/coursebook/:id_coursebook/resources',
    coursebookDigitalAccess: '/api/coursebook/:id_coursebook/digital_access',
    courses: '/api/courses',
    course: '/api/course/:id_course',
    highlights: '/api/highlights',
    readers: '/api/readers',
    reader: '/api/reader/:id_reader',
    readerResources: '/api/reader/:id_reader/resources',
    readersLevels: '/api/readers/levels',
    readersSeries: '/api/readers/series',
    readersSerie: '/api/readers/serie/:id_serie',
    resources: '/api/resources/:section',
    resource: '/api/resource/:section/:id_resource',
    countries: '/api/countries',
    regions: '/api/regions',
    subregions: '/api/subregions',
    schools: '/api/schools',
    settings: '/api/settings',
    menu: '/api/settings/menu',
    mathewaiUrl: '/api/external/mathewai/url/'
};
