import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import ThinkoaiRedirect from 'src/routes/components/thinkoai-redirect';
import MathewaiRedirect from 'src/routes/components/mathewai-redirect';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';


const HomePage = lazy(() => import('src/pages/content/home'));
const SerieTitlesPage = lazy(() => import('src/pages/content/serie-titles'));
const TitlesPage = lazy(() => import('src/pages/content/titles'));
const TitlePage = lazy(() => import('src/pages/content/title'));
const ReadersTitlesPage = lazy(() => import('src/pages/content/readers-titles'));
const ReadersTitlePage = lazy(() => import('src/pages/content/readers-title'));
const ResourcesPage = lazy(() => import('src/pages/content/resources'));
const ResourcePage = lazy(() => import('src/pages/content/resource'));
const CoursesPage = lazy(() => import('src/pages/content/courses'));
const CoursePage = lazy(() => import('src/pages/content/course'));
const ProfilePage = lazy(() => import('src/pages/content/profile'));
const GatePage = lazy(() => import('src/pages/content/gate'));
const MathewaiPage = lazy(() => import('src/pages/content/mathewai'));

export const contentRoutes = [
    {
        path: 'home',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <HomePage />, index: true }
        ],
    },
    {
        path: 'titles',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <TitlesPage />, index: true }
        ],
    },
    {
        path: 'titles/readers/:levelId/:serieId',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <ReadersTitlesPage />, index: true }
        ],
    },
    {
        path: 'titles/readers/:levelId/:serieId/:titleId',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <ReadersTitlePage />, index: true }
        ],
    },
    {
        path: 'titles/:levelId/:serieId',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <SerieTitlesPage />, index: true }
        ],
    },
    {
        path: 'titles/:levelId/:serieId/:titleId',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <TitlePage />, index: true }
        ],
    },
    {
        path: ':slug/resources/:section',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <ResourcesPage />, index: true }
        ],
    },
    {
        path: ':parentSlug/resources/:section/:resourceId/:slug',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <ResourcePage />, index: true }
        ],
    },
    {
        path: ':slug/courses/:section',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <CoursesPage />, index: true }
        ],
    },
    {
        path: ':parentSlug/courses/:section/:courseId/:slug',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <CoursePage />, index: true }
        ],
    },
    {
        path: 'profile',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <ProfilePage />, index: true }
        ],
    },
    {
        path: 'gate',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <GatePage />, index: true }
        ],
    },
    {
        path: 'thinkoai',
        element: (
            <AuthGuard>
                <ThinkoaiRedirect />
            </AuthGuard>
        )
    },
    {
        path: 'mathewai/intro',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={ <LoadingScreen /> }>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { element: <MathewaiPage />, index: true }
        ],
    },
    {
        path: 'mathewai',
        element: (
            <AuthGuard>
                <MathewaiRedirect />
            </AuthGuard>
        )
    },
];
