import _ from 'lodash';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { useDataContext } from 'src/context/data-context';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from 'src/components/logo';
import Image from 'src/components/image';
import LogoM from 'src/assets/images/macmillan-logo.png';
import { useLocales } from 'src/locales';
import { LanguagePopoverText } from '../_common';


// ----------------------------------------------------------------------

export default function AuthModernLayout({ children }) {

    const upMd = useResponsive('up', 'md');
    const theme = useTheme();
    const { t } = useLocales();
    const dataContext = useDataContext();

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: 480,
                px: { xs: 2, md: 8 },
            }}
        >
            <Logo
                sx={{
                    mt: { xs: 2, md: 8 },
                    mb: { xs: 10, md: 8 },
                }}
            />
            <Card
                sx={{
                    py: { xs: 5, md: 0 },
                    px: { xs: 3, md: 0 },
                    boxShadow: { md: 'none' },
                    overflow: { md: 'unset' },
                    bgcolor: { md: 'background.default' },
                }}
            >
                {children}
            </Card>
        </Stack>
    );

    const renderSectionVideo = () => {

        const isVideo = _.get(dataContext, 'settings.login.backgroundType', '') === 'video';

        return <Stack flexGrow={1} sx={{ position: 'relative' }}>
            <Box
                component={ isVideo ? 'video' : 'image' }
                alt='auth'
                autoPlay
                muted
                loop
                src={ _.get(dataContext, 'settings.login.backgroundFile', '') }
                sx={{
                    top: 16,
                    left: 16,
                    objectFit: 'contain',
                    position: 'absolute',
                    width: 'calc(100% - 32px)',
                    height: 'calc(100% - 32px)',
                }}
            />
        </Stack>;

    }

    const renderLinks = (
        <Stack spacing={ 4 } direction='row' sx={{
            mt: 5,
            mx: {
                xs: 'auto',
                md: 0
            },
            p: 2,
            borderRadius: 1,
            px: '16px',
            position: {
                xs: 'relative',
                md: 'absolute'
            },
            display: {
                xs: 'block',
                md: 'flex'
            },
            bottom: 26,
            flexDirection: {
                xs: 'column',
                sm: 'row'
            },
            right: {
                xs: 0,
                md: 26
            },
            maxWidth: {
                xs: 'calc(480px - 16px - 16px)',
                md: '100%'
            },
            width: {
                xs: 'calc(100% - 16px - 16px)',
                md: 'auto'
            },
            backgroundColor: theme.palette.primary.main
        }}>
            <Link sx={{ ml: { xs: 0, md: 2 }, color: theme.palette.primary.contrastText }} variant='body2' href='/assets/legal/condiciones_uso.pdf' rel='noreferrer' target='_blank'>{ t('auth.useConditions') }</Link>
            <Divider sx={{ backgroundColor: theme.palette.primary.contrastText }} orientation='vertical' variant='middle' flexItem />
            <Link sx={{ color: theme.palette.primary.contrastText }} variant='body2' href='/assets/legal/politica_privacidad.pdf' rel='noreferrer' target='_blank'>{ t('auth.privacyPolicy') }</Link>
            <Divider sx={{ backgroundColor: theme.palette.primary.contrastText }} orientation='vertical' variant='middle' flexItem />
            <Link sx={{ mr: { xs: 0, md: 2 }, color: theme.palette.primary.contrastText }} variant='body2' href='/assets/legal/politica_cookies.pdf' rel='noreferrer' target='_blank'>{ t('auth.cookiesPolicy') }</Link>
        </Stack>
    );

    const renderHeaderLinks = (
        <Stack spacing={ 4 }
            direction='row'
            justifyContent='center'
            alignItems='center' sx={{
                top: {
                    xs: '50px',
                    md: -10
                },
                backgroundColor: '#FFFFFF88',
                mt: 5,
                mx: 'auto',
                p: 2,
                borderRadius: 1,
                position: {
                    xs: 'absolute'
                },
                display: 'flex',
                right: {
                    xs: '50%',
                    md: 26
                },
                transform: {
                    xs: 'translate(50%)',
                    md: 'translate(0)'
                },
                maxWidth: {
                    xs: 'calc(480px - 16px - 16px)',
                    md: '100%'
                },
                width: {
                    xs: 'calc(100% - 16px - 16px)',
                    md: 'auto'
                }
            }}
        >
            <Link href='https://www.macmillaneducation.es' target='_blank' rel='noopener' sx={{ display: { xs: 'none', md: 'inline-block' }}}>
                <Image src={ LogoM } sx={{ ml: 2, width: '50%' }} />
            </Link>
            <Divider sx={{ backgroundColor: theme.palette.grey[500], display: { xs: 'none', md: 'inline-block' } }} orientation='vertical' variant='middle' flexItem />
            <Link sx={{ color: theme.palette.primary.main }} variant='body2' href='https://www.macmillaneducation.es/atencion-al-cliente/' rel='noreferrer' target='_blank'>{ t('auth.faq') }</Link>
            <Divider sx={{ backgroundColor: theme.palette.grey[500] }} orientation='vertical' variant='middle' flexItem />
            <LanguagePopoverText />
        </Stack>
    );

    return (
        <Stack
            component='main'
            sx={{
                minHeight: '100vh',
                position: 'relative',
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                '&:before': {
                    width: 1,
                    height: 1,
                    zIndex: -1,
                    content: "''",
                    position: 'absolute',
                    backgroundSize: 'cover',
                    opacity: { xs: 0.24, md: 0 },
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundImage: 'url(/assets/background/overlay_1.jpg)',
                },
            }}
        >
            { renderContent }
            { upMd && renderSectionVideo() }
            { renderHeaderLinks }
            { renderLinks }
        </Stack>
    );
}

AuthModernLayout.propTypes = {
    children: PropTypes.node
};
