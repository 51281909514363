import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { authRoutes } from './auth';
import { contentRoutes } from './content';

// ----------------------------------------------------------------------
const Page404 = lazy(() => import('src/pages/404'));

export default function Router() {
    return useRoutes([

        // SET INDEX PAGE WITH SKIP HOME PAGE
        {
            path: '/',
            element: <Navigate to={ PATH_AFTER_LOGIN } replace />,
        },

        // Auth routes
        ...authRoutes,

        // Content routes
        ...contentRoutes,

        // No match 404
        { path: '/404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },

    ]);
}
