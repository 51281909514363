import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from '../../routes/components';

import LogoImage from '../../assets/images/logo_advantage.svg';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

    const logo = (
        <Box
            ref={ref}
            component="div"
            sx={{
                width: 189,
                height: 63,
                display: 'inline-flex',
                ...sx,
            }}
            {...other}
        >
            <img alt="Logo advantage" src={LogoImage}/>
        </Box>
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
            {logo}
        </Link>
    );
});

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default Logo;