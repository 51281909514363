import { Buffer } from 'buffer';
import { useEffect } from 'react';

import { useDataContext } from 'src/context/data-context';
import axios, { endpoints } from 'src/utils/axios';

const generateThinkoAIToken = (user) => {

    const json = {
        "name": user.firstname,
        "surname": `${user.surname_1} ${user.surname_2}`,
        "email": user.email,
        "schoolId": "",
        "schoolCode": user.id_school,
        "referral": "Macmillan"
    };

    return Buffer.from(JSON.stringify(json)).toString('base64');

} 

export default function ThinkoaiRedirect() {

    const { settings } = useDataContext();

    useEffect(() => {

        (async () => {

            const user = (await axios.get(endpoints.auth.user)).data;

            if (user && settings) {

                const token = generateThinkoAIToken(user);
                window.location.replace(settings.thinkoai?.url?.replace(':token', token));

            }

        })();
    
    }, [settings]);

    return '';
    
}