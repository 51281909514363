// routes
import { paths } from 'src/routes/paths';

// API
export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.content.home; // as '/home'

// GOOGLE
export const GOOGLE_CLIENT_ID = "193190719942-bsrrkme822f9dk6hivqj1rhokqn81675.apps.googleusercontent.com";