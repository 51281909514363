import _ from 'lodash';
import { useEffect } from 'react';

import { useDataContext } from 'src/context/data-context';
import axios, { endpoints } from 'src/utils/axios';
import { useRouter } from 'src/routes/hooks';

export default function MathewaiRedirect() {

    const { settings } = useDataContext();
    const router = useRouter();

    useEffect(() => {

        (async () => {

            const user = (await axios.get(endpoints.auth.user)).data;
            const userEnvironments = (await axios.get(endpoints.auth.environments)).data;

            if (user && userEnvironments && settings) {

                if (_.find(userEnvironments, { 'id_environment': 2 })) {
    
                    (async () => {
                        const data = (await axios.get(endpoints.mathewaiUrl)).data || {};
                        if (data.url) {
                            window.location.replace(data.url);
                        } else {
                            // TODO: 'Mostar mensaje de error y link a home'
                            router.push('/');
                        }
                    })();
    
                } else {
                    // TODO: 'Mostar mensaje de error y link a home'
                    router.push('/');
                }
    
            }
        
        })();

    }, [settings, router]);

    // TODO: Poner un loading ????

    return '';
    
}