import merge from 'lodash/merge';
import {
  es as esAdapter,
  ca as caAdapter,
  enGB as enAdapter,
  eu as euAdapter,
  gl as glAdapter,
  fr as frAdapter,
  pt as ptAdapter,
  de as deAdapter
} from 'date-fns/locale';
// core
import {
  esES as esCore,
  esES as caCore,
  enUS as enCore,
  esES as euCore,
  esES as glCore,
  frFR as frCore,
  ptPT as ptCore,
  deDE as deCore
} from '@mui/material/locale';
// date-pickers
import {
  esES as esDate,
  esES as caDate,
  enUS as enDate,
  esES as euDate,
  esES as glDate,
  frFR as frDate,
  ptBR as ptDate,
  deDE as deDate
} from '@mui/x-date-pickers/locales';
// data-grid
import {
  esES as esDataGrid,
  esES as caDataGrid,
  enUS as enDataGrid,
  esES as euDataGrid,
  esES as glDataGrid,
  frFR as frDataGrid,
  ptBR as ptDataGrid,
  deDE as deDataGrid
} from '@mui/x-data-grid';

/*
Ids de BBDD de Advantage
'1','Castellano','es','es'
'2','Català','ca','ca'
'3','English','en','en'
'4','Euskera','eu','eu'
'5','Galego','ga','gl'
'6','Francés','fr','fr'
'7','Português','pt','pt'
'8','Alemán','de','de'
*/


export const allLangs = [
  {
    label: 'Español',
    value: 'es',
    systemValue: merge(esDate, esDataGrid, esCore),
    adapterLocale: esAdapter,
    icon: 'ph:globe', // flagpack:es Prefieren que no haya bandera
    id: 1 // Id de BBDD de Advantage
  },
  {
    label: 'Català',
    value: 'ca',
    systemValue: merge(caDate, caDataGrid, caCore),
    adapterLocale: caAdapter,
    icon: 'ph:globe', 
    id: 2 // Id de BBDD de Advantage
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enDate, enDataGrid, enCore),
    adapterLocale: enAdapter,
    icon: 'ph:globe', 
    id: 3 // Id de BBDD de Advantage
  },
  {
    label: 'Euskera',
    value: 'eu',
    systemValue: merge(euDate, euDataGrid, euCore),
    adapterLocale: euAdapter,
    icon: 'ph:globe', 
    id: 4 // Id de BBDD de Advantage
  },
  {
    label: 'Galego',
    value: 'gl',
    systemValue: merge(glDate, glDataGrid, glCore),
    adapterLocale: glAdapter,
    icon: 'ph:globe', 
    id: 5 // Id de BBDD de Advantage
  },
  {
    label: 'Français',
    value: 'fr',
    systemValue: merge(frDate, frDataGrid, frCore),
    adapterLocale: frAdapter,
    icon: 'ph:globe', 
    id: 6 // Id de BBDD de Advantage
  },
  {
    label: 'Português',
    value: 'pt',
    systemValue: merge(ptDate, ptDataGrid, ptCore),
    adapterLocale: ptAdapter,
    icon: 'ph:globe', 
    id: 7 // Id de BBDD de Advantage
  },
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: merge(deDate, deDataGrid, deCore),
    adapterLocale: deAdapter,
    icon: 'ph:globe', 
    id: 8 // Id de BBDD de Advantage
  }

];

export const defaultLang = allLangs[0];

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
