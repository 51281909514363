import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { palette as themePalette } from 'src/theme/palette';
import { useRouter } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { useDataContext } from 'src/context/data-context';
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useLocales } from 'src/locales';


// ----------------------------------------------------------------------

export default function AccountPopover() {
    const { t } = useLocales();

    const router = useRouter();

    const { logout } = useAuthContext();
    const { user } = useDataContext();

    const { enqueueSnackbar } = useSnackbar();

    const popover = usePopover();

    const palette = themePalette();

    const handleLogout = async () => {
        try {
            await logout();
            popover.onClose();
            router.replace('/');
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t('auth.unableToLogout'), { variant: 'error' });
        }
    };

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    ...(popover.open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <Avatar
                    src={user?.photo}
                    alt={`${user?.firstname} ${user?.surname_1} ${user?.surname_2}`}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }}
                />
            </IconButton>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>

                <Stack sx={{ p: 1 }}>
                    <MenuItem onClick={() => { popover.onClose(); router.push('/profile'); }}>
                        { t('profile.myProfile') }
                    </MenuItem>
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={handleLogout}
                    sx={{ m: 1, fontWeight: 'fontWeightBold', color: palette.primary.main }}
                >
                    { t('auth.logout') }
                </MenuItem>
            </CustomPopover>
        </>
    );
}
