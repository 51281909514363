import _ from 'lodash';
import { useState, memo, useCallback, useEffect } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useEventListener } from 'src/hooks/use-event-listener';
import { useDataContext } from 'src/context/data-context';
import { paths } from 'src/routes/paths';
import { paramCase } from 'src/utils/change-case';
import { useLocales } from 'src/locales';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useRouter } from 'src/routes/hooks';
import SearchNotFound from 'src/components/search-not-found';
import ResultItem from './result-item';

const extractResourceSectionsFromMenuOptions = (menu, menuOptions = []) => {
    _.each(menu, menuOption => {
        if (menuOption.type === 'resources') {
            menuOptions.push(menuOption);
        }
        if (menuOption.type === 'submenu') {
            menuOptions = extractResourceSectionsFromMenuOptions(menuOption.children, menuOptions);
        }
    });
    return _.uniq(_.compact(menuOptions));
}

function Searchbar() {

    const { t } = useLocales();
    const theme = useTheme();
    const router = useRouter();
    const search = useBoolean();
    const mdUp = useResponsive('up', 'md');
    const [searchQuery, setSearchQuery] = useState('');
    const dataContext = useDataContext();
    const [items, setItems] = useState([]);

    const handleClose = useCallback(() => {
        search.onFalse();
        setSearchQuery('');
    }, [search]);

    const handleKeyDown = (event) => {
        if (event.key === 'k' && event.metaKey) {
            search.onToggle();
            setSearchQuery('');
        }
    };

    useEventListener('keydown', handleKeyDown);

    const handleClick = useCallback(
        (path) => {
            if (path.includes('http')) {
                window.open(path);
            } else {
                router.push(path);
            }
            handleClose();
        },
        [handleClose, router]
    );

    const handleSearch = useCallback((event) => {
        setSearchQuery(event.target.value);
    }, []);

    useEffect(() => {

        const searchMap = _.map(dataContext.coursebooks, coursebook => {
            const serie = _.find(dataContext.series, {id_serie: _.get(coursebook, 'series[0]')});
            return (
            {
                title: coursebook.name,
                subtitle: serie.name,
                path: paths.content.title
                        .replace(':levelId', serie.id_level)
                        .replace(':serieId', coursebook.series[0])
                        .replace(':titleId', coursebook.id_coursebook)
                        .replace(':titleName', paramCase(coursebook.name)),
                image: coursebook.file,
                group: 'Coursebook'
            });
        });

        _.each(extractResourceSectionsFromMenuOptions(dataContext.menu), menuOption => {
            if (menuOption.type === 'resources') {
                _.each(dataContext[`resources${menuOption.section}`], resource => {
                    const type = _.find(dataContext.types, {id_type: _.get(resource, 'types[0]')});
                    searchMap.push({
                        title: resource.name,
                        subtitle: type?.name || '',
                        path: paths.content.resource  //  '/:slug/resources/:section/:resourceId/:childSlug'
                                .replace(':slug', menuOption.slug)
                                .replace(':section', menuOption.section)
                                .replace(':resourceId', resource.id_resource)
                                .replace(':childSlug', paramCase(resource.name)),
                        image: resource.file,
                        group: t(`menu.${menuOption.ref}`)
                    });
                });
            }
        });

        setItems(searchMap);

    }, [dataContext, t]);

    const applyFilter = useCallback(({ inputData, query }) => {
        if (query) {
            const queryTags = _.compact(query.split(' '));
            _.each(queryTags, queryTag => {
                inputData = inputData.filter(
                    item =>
                        item.title.toLowerCase().includes(queryTag.toLowerCase()) ||
                        item.subtitle.toLowerCase().includes(queryTag.toLowerCase())
                );
            });
        }
// TODO: Solventar problema con lo de que no encuentre números.

        // console.log(inputData, inputData.slice(0, 2));
        // return [ ...inputData.slice(0, 2) ];
        return inputData;
    }, []);

    const groupedData = useCallback(array => {
        const group = array.reduce((groups, item) => {
            groups[item.group] = groups[item.group] || [];
            groups[item.group].push(item);
            return groups;
        }, {});
        return group;
    }, []);

    const dataFiltered = applyFilter({
        inputData: items,
        query: searchQuery,
    });

    const renderItems = () => {
        const data = groupedData(dataFiltered);

        return Object.keys(data)
            .sort((a, b) => -b.localeCompare(a))
            .map((group, index) => (
                <List key={group || index} disablePadding>
                    {data[group].map((item) => {
                        const { title, subtitle, path, image } = item;

                        const partsTitle = parse(title, match(title, searchQuery));
                        // const partsPath = parse(path, match(path, searchQuery));
                        const partsSubtitle = parse(subtitle, match(subtitle, searchQuery));

                        return (
                            <ResultItem
                                title={partsTitle}
                                subtitle={partsSubtitle}
                                image={image}
                                key={`${title}${path}`}
                                groupLabel={searchQuery && group}
                                onClickItem={() => handleClick(path)}
                            />
                        );
                    })}
                </List>
            ));
    };

    const renderButton = (
        <Stack direction="row" alignItems="center" onClick={ search.onTrue }>
            <IconButton sx={{ borderRadius: { xs: '50%', md: '25px' }, border: '1px solid rgba(145, 158, 171, 0.2)' }}>
                <Iconify icon="eva:search-fill" />
                { mdUp && <Box sx={{ fontSize: '1rem', paddingRight: '60px' }}>&nbsp;{ t('common.search') }</Box> }
            </IconButton>
        </Stack>
    );

    return (
        <>
            { renderButton }

            <Dialog
                fullWidth
                maxWidth="md"
                open={search.value}
                onClose={handleClose}
                transitionDuration={{
                    enter: theme.transitions.duration.shortest,
                    exit: 0,
                }}
                PaperProps={{
                    sx: {
                        mt: 15,
                        overflow: 'unset',
                    },
                }}
                sx={{
                    [`& .${dialogClasses.container}`]: {
                        alignItems: 'flex-start',
                    },
                }}
            >
                <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
                    <InputBase
                        fullWidth
                        autoFocus
                        placeholder={t('common.search')}
                        value={searchQuery}
                        onChange={handleSearch}
                        startAdornment={
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                        }
                        endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
                        inputProps={{
                            sx: { typography: 'h6' },
                        }}
                    />
                </Box>

                <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
                    { (searchQuery && dataFiltered.length < 3) || searchQuery.length < 3 ? <SearchNotFound query={searchQuery} sx={{ py: 10 }} /> : renderItems()}
                </Scrollbar>
            </Dialog>
        </>
    );
}

export default memo(Searchbar);
