import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

const initialState = {
    user: null,
    loading: true,
};

const reducer = (state, action) => {
    if (action.type === 'INITIAL') {
        return {
            loading: false,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGIN') {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    if (action.type === 'REGISTER') {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGOUT') {
        return {
            ...state,
            user: null,
        };
    }
    return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem(STORAGE_KEY);

            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken);

                const response = await axios.get(endpoints.auth.user);

                const user = response.data;

                dispatch({
                    type: 'INITIAL',
                    payload: {
                        user,
                    },
                });
            } else {
                dispatch({
                    type: 'INITIAL',
                    payload: {
                        user: null,
                    },
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: 'INITIAL',
                payload: {
                    user: null,
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // LOGIN
    const login = useCallback(async (email, password) => {
        const data = {
            email,
            password,
        };

        await successLogin(data);
    }, []);

    // LOGIN WITH GOOGLE
    const loginWithGoogle = useCallback(async (google_access_token) => {
        const data = {
            google_access_token
        };

        await successLogin(data);
    }, []);

    // LOGIN: by user/pass or by google
    const successLogin = async (data) => {
        const response = await axios.post(endpoints.auth.login, data);

        const { token } = response.data;

        setSession(token);

        const responseMe = await axios.get(endpoints.auth.user);

        const user = responseMe.data;

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        });
    }

    // REGISTER
    const register = useCallback(async (email, password, firstName, lastName) => {
        const data = {
            email,
            password,
            firstName,
            lastName,
        };

        const response = await axios.post(endpoints.auth.register, data);

        const { token, user } = response.data;

        localStorage.setItem(STORAGE_KEY, token);

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        });
    }, []);

    // LOGOUT
    const logout = useCallback(async () => {
        setSession(null);
        dispatch({
            type: 'LOGOUT',
        });
    }, []);

    // ----------------------------------------------------------------------

    const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

    const status = state.loading ? 'loading' : checkAuthenticated;

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            method: 'jwt',
            loading: status === 'loading',
            authenticated: status === 'authenticated',
            unauthenticated: status === 'unauthenticated',
            //
            login,
            loginWithGoogle,
            register,
            logout,
        }),
        [login, loginWithGoogle, logout, register, state.user, status]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};
